import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Footer from 'components/Footer/Footer';

import './Layout.scss';

const modes = {
  ERROR: 'error',
  SUCCESS: 'success',
  DEFAULT: 'default',
};

const Layout = ({children, mode, title, text}) => {

  return (
    <section className={classNames('layout', `mode-${mode}`)}>
      {title ? <h1>{title}</h1> : null}
      {text ? <p>{text}</p> : null}
      {children}
      <Footer/>
    </section>
  );

}

Layout.defaultProps = {
  mode: modes.DEFAULT,
  title: undefined,
  text: undefined,
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
  mode: PropTypes.oneOf(_.values(modes)),
  title: PropTypes.string,
  text: PropTypes.string,
};


Layout.modes = modes;

export default Layout;
