import React from 'react';

import './Footer.scss';

const Footer = () => {

  return (
    <footer>
        <a href="https://wa.me/972542096666" target="_blank" rel="noreferrer">Made by Nir Peled</a>
    </footer>
  );

}

export default Footer;
