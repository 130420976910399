import _ from 'lodash';
import React from 'react';
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import Cookies from 'js-cookie'

import Passcode from 'components/Passcode/Passcode';
import Layout from 'components/Layout/Layout';

const firebaseConfig = {
  apiKey: "AIzaSyB2RRtzYF3W13fUfN7vFKVsFpryhao7bps",
  authDomain: "harimon-2022.firebaseapp.com",
  projectId: "harimon-2022",
  storageBucket: "harimon-2022.appspot.com",
  messagingSenderId: "391246812144",
  appId: "1:391246812144:web:717d1ba89a2a7506d48335",
  measurementId: "G-169HVBYE4L"
};

const app = initializeApp(firebaseConfig);

getAnalytics(app);

const COOKIE_NAME = 'passcode';

const statues = {
  IDLE: 'idle',
  CONNECTING: 'connecting',
  OPENING: 'opening',
};

const App = () => {

  const passcode = Cookies.get(COOKIE_NAME);
  const [isAuthenticated, setIsAuthenticated] = React.useState(passcode === Passcode.SECRET);
  const [isOnline, setIsOnline] = React.useState(true);
  const [status, setStatus] = React.useState(statues.IDLE);
  const isOff = true;

  React.useEffect(() => {
    window.addEventListener('online', () => setIsOnline(true));
    window.addEventListener('offline', () => setIsOnline(false));
  }, []);

  const openGate = () => {

    fetch('https://maker.ifttt.com/trigger/openGate/json/with/key/b4uu0jv2Py4a-j7B1S3m_5', {
      mode: 'no-cors',
      headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

  };

  const onOpen = () => {

    setStatus(statues.CONNECTING);

    openGate();
    _.delay(openGate, 2000);
    _.delay(openGate, 4000);

    _.delay(() => {
      setStatus(statues.OPENING);
    }, 2000);

    _.delay(() => {
      setStatus(statues.IDLE);
    }, 12000)

  };

  if (isOff) {

    return (
      <Layout mode={Layout.modes.ERROR} title="App is offline" text="We'll be back soon"/>
    );

  }

  if (!isOnline) {

    return (
      <Layout mode={Layout.modes.ERROR} title="No signal" text="Check your internet connection"/>
    );

  }

  if (!isAuthenticated) {

    return (
      <Layout>
        <Passcode onSuccess={passcode => {
          setIsAuthenticated(true);
          Cookies.set(COOKIE_NAME, passcode, {expires: 7});
        }}/>
      </Layout>
    );

  }

  if (status === statues.CONNECTING) {

    return (
      <Layout title="Connecting..."/>
    );

  }

  if (status === statues.OPENING) {

    return (
      <Layout mode={Layout.modes.SUCCESS} title="Connected!" text="Gate will open in a few seconds"/>
    );

  }

  return (
    <Layout>
      <button onClick={onOpen}/>
    </Layout>
  );

}

export default App;
