import _ from 'lodash';
import React from 'react';

import './Passcode.scss';

const SECRET = '1368';

const Passcode = ({onSuccess}) => {

  const inputRefs = {
    0: React.useRef(null),
    1: React.useRef(null),
    2: React.useRef(null),
    3: React.useRef(null),
  };

  const [digit1, setDigit1] = React.useState('');
  const [digit2, setDigit2] = React.useState('');
  const [digit3, setDigit3] = React.useState('');
  const [digit4, setDigit4] = React.useState('');

  const inputValues = {
    0: {value: digit1, setValue: setDigit1},
    1: {value: digit2, setValue: setDigit2},
    2: {value: digit3, setValue: setDigit3},
    3: {value: digit4, setValue: setDigit4},
  };

  const onKeyUp = event => {

    const index = Number(event.target.dataset.index);
    const length = _.size(event.target.value);

    // handle backspace
    if (length === 0) {
      const previous = inputRefs[index - 1];

      if (previous && previous.current) {
        previous.current.focus();
        inputValues[index - 1].setValue('');
      }
    }

    // jump to next input
    if (length === 1) {
      const next = inputRefs[index + 1];

      if (next && next.current) {
        next.current.focus();
      }
    }

  };

  const onChange = event => {
    const index = Number(event.target.dataset.index);
    inputValues[index].setValue(event.target.value);
  };

  React.useEffect(() => {

    const passcode = _.join([digit1, digit2, digit3, digit4], '');

    if (passcode !== SECRET) {
      return;
    }

    onSuccess(passcode);

  }, [digit1, digit2, digit3, digit4, onSuccess])

  return (
    <section className="passcode">
      {_.times(4, index => <input
        key={index}
        name={`password-${index}`}
        maxLength={1}
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        type="tel"
        data-index={index}
        onKeyUp={onKeyUp}
        ref={inputRefs[index]}
        autoFocus={index === 0}
        value={inputValues[index].value}
        onChange={onChange}
      />)}
    </section>
  );

}

Passcode.SECRET = SECRET;

export default Passcode;
